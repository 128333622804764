import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Container,
  Input,
  CircularProgress,
  // Box,
} from "@material-ui/core";
// import { ReactComponent as ImageCoins } from "../images/income.svg";
import { payDue } from "../services/POST/payDue";
import getCredit from "../services/GET/getCredit";
import DuesForMonths from "../components/payDues/DuesForMonths";
import Loading from "../components/loading";
import { checkPendingPayment } from "../services/API";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "1em",
    marginTop: 40,
  },
  title: {
    fontSize: "1.3em",
    marginBottom: "0.5em !important",
    marginTop: "1.5em",
  },
  fundsBox: {
    margin: "auto",
    boxShadow: "1px 2px 3px 2px lightGray",
    borderRadius: 20,
  },
  innerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 120,
    marginBottom: 40,
    paddingTop: 6,
    paddingBottom: 6,
    borderTop: "1px lightGray solid",
    borderBottom: "1px lightGray solid",
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  innerBoxPay: {
    marginTop: 1,
  },
  innerBoxInfo: {
    marginTop: 0,
    marginBottom: 0,
    borderBottom: "none",
    borderTop: "none",
  },
  left: {
    width: "70%",
    fontSize: 14,
  },
  right: {
    width: "30%",
    fontSize: 40,
    margin: 0,
  },
  btn: {
    textAlign: "center",
    width: "100%",
  },
  image: {
    width: "60%",
  },
  input: {
    "& .MuiInput-input": {
      textAlign: "end",
      paddingRight: 10,
      borderBottom: "none",
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  },
}));

const PayDues = (props) => {
  const [amount, setAmount] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const phone = localStorage.getItem("tokenCFS");
  const token = localStorage.getItem("tokenCFS");
  const creditId = localStorage.getItem("creditId");
  const [currentCredit, setCurrentCredit] = React.useState(10);
  const [hasPending, setHasPending] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  
  const classes = useStyles();
  console.log(currentCredit);
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setLoading(true);
    const resp = await getCredit(token, creditId);
    const { hasPendingDues, msg } = await checkPendingPayment();

    if (hasPendingDues) {
      setHasPending(msg);
    } else {
      setHasPending(false);
    }

    setCurrentCredit(resp.data);
    setLoading(false);
  }

  useEffect(() => {
    if (+amount > currentCredit.totalDue) setAmount(currentCredit.totalDue);
  }, [amount]);

  const handlePay = async () => {
    setAmount(0);
    setDisabled(true);
    setLoading(true);
    const formattedAmount = Number(amount).toFixed(2);
    const resp = await payDue(creditId, formattedAmount, phone);
    if (!resp) {
      //   alert("something went wrong");
    } else {
      // alert("due payed")
    }

    setLoading(false);
    setDisabled(false);
  };

  if (loading) {
    return <Loading />;
  }

  const InfoComponent = ({ text, value }) => (
    <Container className={[classes.innerBox, classes.innerBoxInfo].join(" ")}>
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.left}
        style={{ fontWeight: 700 }}
      >
        {text}
      </Typography>
      <Typography style={{ fontWeight: 700 }}>
        {Number(value).toFixed(2)} BGN
      </Typography>
    </Container>
  );

  return (
    <Container className={classes.mainContainer}>
      <Typography align="center" className={classes.title}>
        Вашето задължение включва
      </Typography>
      {/* <Box display="flex" justifyContent="center" alignItems="center">
        <ImageCoins className={classes.image} />
      </Box> */}

      <InfoComponent
        text="Главница"
        value={Number(currentCredit.utilisedLimit).toFixed(2)}
      />

      <Typography style={{ marginBlock: 10 }} align="center">
        Можете да погасите главницата по всяко време. Ако я погасите, няма да Ви
        бъдат начислявани повече лихви и такси. Ако продължите да я ползвате,
        дължите заплащане на лихва и такса, както са описани във Вашия договор.
      </Typography>

      <InfoComponent
        text="Общо лихви"
        value={currentCredit.totalFeesAmountDue}
      />
      <InfoComponent
        text="Общо такса разсрочване"
        value={currentCredit.totalDelayFeesAmountDue}
      />
      <InfoComponent
        text="Общо"
        value={Number(currentCredit.totalDue).toFixed(2)}
      />

      <Container className={[classes.innerBox, classes.innerBoxPay].join(" ")}>
        <Typography align="left" className={classes.left}>
          Въведи сума, която желаеш да платиш
        </Typography>
        <Input
          value={amount}
          disableUnderline={true}
          defaultValue={amount}
          onChange={({ target: { value } }) => setAmount(value)}
          className={classes.input}
          placeholder="00.00"
          type="number"
          inputProps={{ step: 0.01, max: 1000, min: 0 }}
          endAdornment={<Typography>BGN</Typography>}
        />
      </Container>
      <div className={classes.btn}>
        <Button
          disabled={+amount === 0 || hasPending || disabled}
          className={classes.btn}
          onClick={handlePay}
          color="primary"
          variant="contained"
        >
          {loading ? <CircularProgress /> : "Плати задължения"}{" "}
        </Button>
        {hasPending && (
          <Typography style={{ marginTop: 20 }} align="center" color="error">
            {hasPending}
          </Typography>
        )}
      </div>
      {currentCredit && <DuesForMonths {...currentCredit} />}
    </Container>
  );
};

export default PayDues;
