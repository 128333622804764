const axios = require("axios").default;

const HttpService = axios.create();

HttpService.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      alert("You are not authorized");
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      // todo something for all type of err responses
      window.location = "./error";
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default HttpService;
