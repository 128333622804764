import React, { useEffect, useState } from "react";
// import Container from '@material-ui/core/Container';
// import Typography from '@material-ui/core/Typography';
import { makeStyles, Button, CircularProgress } from "@material-ui/core";
import Loading from "../components/loading";
import { handleStart } from "../services/POST/sendPhone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LANDING_TEXTS, ROUTES, STATUS } from "../utils/constants";
import { checkPermissionRequest } from "../services/GET/permissionReq";
import DocumentLinkSef from "../components/documentLinkSef";
import LandingInfo from "../components/landing/LandingInfo";
import creditProducts from "../services/GET/creditProducts";

// import ModalInfo from "../components/ModalInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "15vh",
    // height: "80vh",
    // maxHeight: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "column",
    marginInline: 20,
    "& .form-control": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 20,
      "&:focus": {
        outline: "none!important",
        borderColor: "#ff4731",
        boxShadow: "0 0 4px #ff4731",
        opacity: 0.7,
      },
    },
  },
  phoneBox: {
    height: "10vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  link: {
    display: "flex",
    justifyContent: "center",
    width: 200,
  },
  infoBox: {
    boxShadow: "1px 1px 8px 2px #DCDCDC",
    borderRadius: "20px",
    minHeight: "fit-content",
  },
  btn: {
    marginTop: 20,
    marginBottom: 50,
  },
}));

const Landing = ({ location, match }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const [call, setCall] = useState(true);
  // const [url, setURL] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [sef, setSef] = useState("");
  const [text, setText] = useState("");

  const [url2, setURL2] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setLoading(true);
    const data = await handleStart();
    const { data: resp } = await creditProducts();
    setText(resp[0].description);
    setSef(resp[0].sefLink);
    setInterval(async () => {
      if (call) {
        const { status, phone } = await checkPermissionRequest(null, data.hash);
        localStorage.setItem("tokenCFS", phone);

        if (status === STATUS.CONFIRMED) {
          history.push("/credit");
          setCall(false);
          window.location.reload();
        }

        if (status === STATUS.IDENTIFIED_WITH_PASSPORT) {
          history.push(ROUTES.IDENTIFIED_WITH_PASSPORT);
          setCall(false);
          window.location.reload();
        }
      }
    }, 1000);

    // setURL(data.url);
    setURL2(data.url2);
    setLoading(false);
  }

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 300,
          left: 0,
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <LandingInfo text={text} bold={1} />
      <DocumentLinkSef text="Стандартен европейски формуляр" url={sef} />
      <LandingInfo text={LANDING_TEXTS[2]} />

      {localLoading && (
        <Button className={classes.btn} variant="contained" color="primary">
          <CircularProgress color="inherit" />
        </Button>
      )}
      {location.search.includes("?loginText=true")
        ? !localLoading && (
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              href={url2}
            >
              <Button
                className={classes.btn}
                onClick={() => setLocalLoading(true)}
                disabled={loading}
                variant="contained"
                color="primary"
              >
                Влез със Settle
              </Button>
            </a>
          )
        : !localLoading && (
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              href={url2}
            >
              <Button
                className={classes.btn}
                onClick={() => setLocalLoading(true)}
                disabled={loading}
                variant="contained"
                color="primary"
              >
                Продължи
              </Button>
            </a>
          )}

      {/* <a
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
        href={url2}
      >
        <Button
          className={classes.btn}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          Url 2
        </Button>
      </a> */}
    </div>
  );
};

export default Landing;
