import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import creditProducts from "../services/GET/creditProducts";
import apply from "../services/POST/apply";
import postConsent from "../services/POST/postConsents";
import getCredit from "../services/GET/getCredit";
import ConsentsApply from "../components/consents/consents_apply";
import ConsentsPEP from "../components/consents/consents_PEP";
import ConsentsIncome from "../components/consents/consents_income";
import Loading from "../components/loading";
import ConsentsDocument from "../components/consents/consents_document";
import APPLICATION_STATUS from "../utils/APPLICATION_STATUS";
import checkToken from "../services/GET/checkToken";
import {
  ACCEPT_CONTRACT,
  ACCEPT_GENERAL_TERMS,
  ACCEPT_SEF,
  FUNDS_ORIGIN,
  PEP,
  RELATED_TO_PEP,
  ROUTES,
} from "../utils/constants";

/* TODO 
created component/screen fro each different type of consents/consent
with the specific consents structure 
current causes error on income-consents (Декларация за приходи)
*/

// const useStyles = makeStyles({
//     pageContainer: {
//         display: "flex"
//     }
// })

const PermissionsScreen = () => {
  // const styles = useStyles();

  const history = useHistory();

  const token = localStorage.getItem("tokenCFS");
  const selectedCreditProduct = localStorage.getItem("selectedCreditProduct");
  const selectedCreditName = localStorage.getItem("selectedCreditName");

  // const CurrentCreditId = '178'
  // const token = "con";
  // const selectedCreditProduct = null
  // get data creditId,token and selectedCreditProduct --- if needed from props

  const [credit, setCredit] = useState({});
  const [consents, setConsents] = useState([]);
  const [selectedConsent, setSelectedConsent] = useState([]);
  const [title, setTitle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  console.log("====================================");
  console.log(name);
  console.log("====================================");
  useEffect(async () => {
    const { data } = await checkToken(token);

    if (data && data.nextUrl.includes("/credit/")) {
      const creditId = data.nextUrl.split("/").pop();
      localStorage.setItem("creditId", creditId);
    }

    const CurrentCreditId = localStorage.getItem("creditId");

    if (selectedCreditProduct === null) {
      await getCredit(token, CurrentCreditId).then((resp) => {
        // add check for status here
        const status = resp.data.applicationStatus;
        const message = resp.data.messageForCustomer;
        // reuse redirecting logic
        if (status === APPLICATION_STATUS.READY) {
          return history.push({ pathname: "/credit-approved" });
        } else if (
          status === APPLICATION_STATUS.MANUAL_PEP_STATUS_CHECK_NEEDED ||
          status === APPLICATION_STATUS.FAILED_PASSPORT_INVALID ||
          status === APPLICATION_STATUS.PENDING
        ) {
          return history.push({
            pathname: ROUTES.REQUEST_SUBMITTED_PATH(message),
          });
        } else if (status === APPLICATION_STATUS.REJECTED) {
          history.push(ROUTES.PERMISSION_REQUEST_REJECTED_PATH(message));
        } else if (status === APPLICATION_STATUS.IDENTIFIED_WITH_PASSPORT) {
          history.push(ROUTES.WAIT_FOR_PATH(message));
        } else if (status === APPLICATION_STATUS.CREDIT_SCORING_IN_PROGRESS) {
          history.push(ROUTES.SCORING_SCREEN_PATH(message));
        }
        // redirecting to the screen of current application status
        console.log(resp);
        setConsents(resp.data.currentConsent);
        setCredit(resp.data);
        setTitle(resp.data.currentConsent.title);
        setName(resp.data.currentConsent.name);
        setLoading(false);
      });
    } else {
      creditProducts(token).then((resp) => {
        // getting the selected product by "@id" from the previous page
        // add check for status here
        const currentProduct = resp.data.find((product) => {
          return product["@id"] === selectedCreditProduct;
        });
        setConsents(currentProduct.consents);
        setLoading(false);
      });
    }
  }, []);

  const applyHandler = (e) => {
    e.preventDefault();

    setLoading(true);

    apply(token, consents, selectedCreditProduct, selectedCreditName)
      .then((resp) => {
        setConsents(resp.data.currentConsent);
        setCredit(resp.data);
        setTitle(resp.data.currentConsent.title);
        setName(resp.data.currentConsent.name);

        localStorage.removeItem("selectedCreditProduct");
        localStorage.removeItem("selectedCreditName");
        setLoading(false);
      })
      .then()
      .catch((e) => {
        // window.location.href = "/error"
      });
  };

  const sendConsents = () => {
    localStorage.setItem("isNewCredit", true);
    const creditId = credit.creditId;
    const creditProductId = credit["@id"];
    setLoading(true);
    postConsent(
      token,
      creditId,
      creditProductId,
      [selectedConsent],
      title,
      credit.currentConsent.type
    ).then((resp) => {
      setSelectedConsent([]);
      const status = resp.data.applicationStatus;
      const message = resp.data.messageForCustomer;
      // reuse redirecting logic
      if (status === APPLICATION_STATUS.READY) {
        const creditId = resp.data.creditId;
        localStorage.setItem("creditId", creditId);
        return history.push({
          pathname: "/credit-approved",
          state: { creditId, token },
        });
      } else if (
        status === APPLICATION_STATUS.MANUAL_PEP_STATUS_CHECK_NEEDED ||
        status === APPLICATION_STATUS.FAILED_PASSPORT_INVALID ||
        status === APPLICATION_STATUS.PENDING
      ) {
        return history.push({
          pathname: ROUTES.REQUEST_SUBMITTED_PATH(message),
        });
      } else if (status === APPLICATION_STATUS.REJECTED) {
        history.push(ROUTES.PERMISSION_REQUEST_REJECTED_PATH(message));
      } else if (status === APPLICATION_STATUS.IDENTIFIED_WITH_PASSPORT) {
        history.push(ROUTES.WAIT_FOR_PATH(message));
      } else if (status === APPLICATION_STATUS.CREDIT_SCORING_IN_PROGRESS) {
        history.push(ROUTES.SCORING_SCREEN_PATH(message));
      }

      setCredit(resp.data);
      if (resp.data.currentConsent.options) {
        setConsents(resp.data.currentConsent);
        //TODO fix the consents here
      }
      setTitle(resp.data.currentConsent.title);
      setName(resp.data.currentConsent.name);
      setLoading(false);
    });
  };

  if (loading) {
    return <Loading />;
  }
  if (
    name === ACCEPT_SEF ||
    name === ACCEPT_GENERAL_TERMS ||
    name === ACCEPT_CONTRACT
  ) {
    console.log(consents);
    return (
      <ConsentsDocument
        setSelectedConsent={setSelectedConsent}
        title={title}
        sendConsents={sendConsents}
        buttonText={credit.currentConsent.buttonText}
        document={consents.document}
      />
    );
  }

  if (name === FUNDS_ORIGIN) {
    return (
      <ConsentsIncome
        consents={consents}
        setSelectedConsent={setSelectedConsent}
        sendConsents={sendConsents}
        currentSelected={selectedConsent}
      />
    );
  }

  if (name === PEP || name === RELATED_TO_PEP) {
    return (
      <ConsentsPEP
        consents={consents}
        setSelectedConsent={setSelectedConsent}
        label={selectedConsent.label}
        sendConsents={sendConsents}
      />
    );
  }

  return <ConsentsApply consents={consents} applyHandler={applyHandler} />;
};

export default PermissionsScreen;
