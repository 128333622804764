import { APPLY } from "../../utils/constants";
import HttpService from "../http";

const apply = (token, consents, creditProductId, creditProductName) => {
  console.log(token, consents, creditProductId, creditProductName);

  const body = JSON.stringify({
    consents: consents,
    creditProductId: creditProductId,
    creditProductName: creditProductName,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-language": "bg",
      "X-Request-ID": "3a0bbcaa-c46e-4e0b-baec-06e6e64616c6",
      "x-settapp-token": token,
    },
  };

  return HttpService.post(APPLY, body, config).catch((e) => {
    // alert(JSON.stringify(e.message ))
  });
};
// 1ac9b2d1-a7d3-4612-9421-93e099daa650
// 823a156c-828e-4831-8bc9-cf32c4ffd759
export default apply;
