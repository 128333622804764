import React from "react";
import { Button, Typography } from "@material-ui/core";
import ButtonBox from "../../components/consents/ButtonBox";
import { ReactComponent as CardIcon } from "../../images/id-card.svg";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    marginTop: "15vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const IdentifiedWithPassport = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CardIcon width={"90vw"} />
      <ButtonBox>
        <Typography style={{ margin: 20, marginTop: -220 }}>
          За да продължите процеса по кандидатстване, моля да се идентифицирате
          отново с лична карта, а не с паспорт. След това, натиснете отново
          бутон “Кандидатствай за кредит”, който може да намерите в началния
          екран на приложението
        </Typography>
        <a href="settle-sandbox://settledemo.page.link/?action=settle:modal&customProps=eyJyb3V0ZU5hbWUiOiJWZXJpZnlBY2NvdW50Iiwic3RhY2siOiJIb21lIn0=&dismissAfterInteraction=true">
          <Button variant="contained" color="primary">
            Започни нова идентификция
          </Button>
        </a>
      </ButtonBox>
    </div>
  );
};

export default IdentifiedWithPassport;
