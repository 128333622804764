import React from "react";
import ReactDOM from "react-dom";
import PageRouter from "./PageRouter";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "react-phone-input-2/lib/material.css";
import { ContextProvider } from "./context/Context";

const theme = createTheme({
  spacing: 10,
  palette: {
    primary: {
      main: "#233F67",
    },
    secondary: {
      main: "#ed1d24",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        width: "80%",
        borderRadius: "24px",
        maxWidth: 300,
      },
    },
  },
  typography: {
    body1: {
      fontSize: "12",
      fontWeight:400
    },
    allVariants: {
      color: "#4D596F",
    },
    subtitle2: {
      fontSize: 16,
    },
    button: {
      textTransform: "none",
      fontSize: 16,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <PageRouter />
      </ThemeProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
