import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as FailureIcon } from "../../images/failure.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "15vh",
    height: "46vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const Failure = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FailureIcon />
      <div>
        <Typography align="center" variant="subtitle2">
          Отказано съгласие за
        </Typography>
        <Typography variant="subtitle2"> споделяне на лични данни</Typography>
      </div>
    </div>
  );
};

export default Failure;
