import { PAY_DUE } from "../../utils/constants";
import HttpService from "../http";


export const payDue = async (creditId, sum, phone) => {

    try {
        const { data } = await HttpService.post(PAY_DUE, {
            creditId: +creditId,
            sum: +sum
        }, {
            headers: {
                "x-settapp-token": phone
            }
        });

        return data;

    } catch (error) {
        window.location.href = "/error"
        console.log(error);
    }
}