import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CreditProducts from './pages/CreditProducts';
import PermissionScreen from './pages/PermissionsScreen'
import RequestSubmittedScreen from './pages/RequestSubmittedScreen';
import CreditApprovedScreenHome from "./pages/CreditApprovedScreenHome";
import Details from './pages/Details';
import History from './pages/History';
import DuesScreen from "./pages/DuesScreen";
import PayDues from "./pages/PayDues";
import SelectCreditScreen from "./pages/SelectCreditScreen";
import Credit from './pages/Credit';
import Landing from './pages/Landing'
// import PoliticallyExposedPersonScreen from './pages/PoliticallyExposedPersonScreen';
// import EmailSubscriptionScreen from './pages/EmailSubscriptionScreen';
import Layout from './Layout';
import Failure from './pages/static/Failure';
import UiElements from './pages/static/UiElements';
import { ROUTES } from './utils/constants';
import PermissionRequest from './pages/PermissionRequest';
import Success from './pages/static/Success';
import Rejected from './pages/static/Rejected';
import Ui from './pages/static/Ui';
import PageErrorBoundary from './components/PageErrorBoundary';
import Error from './pages/static/Error';
import IdentifiedWithPassport from './pages/static/IdentifiedWithPassport';
import WaitFor from './pages/static/WaitFor';
import Scoring from './pages/static/Scoring';

function PageRouter() {
  console.log(process.env.REACT_APP_BACK_TO_APP_URL)
  return (
    <Router>
      <Switch>
        <Layout>
          {/* paths will be changed */}
          <PageErrorBoundary>
            <Route exact path="/" component={Landing}></Route>

            <Route exact path={ROUTES.CREDIT} component={Credit}></Route>
            <Route exact path="/credit-products" component={CreditProducts}></Route>
            <Route exact path="/permission-screen" component={PermissionScreen}></Route>
            <Route exact path={ROUTES.REQUEST_SUBMITTED} component={RequestSubmittedScreen}></Route>
            <Route exact path="/credit-approved" component={CreditApprovedScreenHome}></Route>

            <Route exact path="/details" component={Details}></Route>
            <Route exact path="/history" component={History}></Route>
            <Route exact path="/dues" component={DuesScreen}></Route>
            <Route exact path={ROUTES.PAY_DUES} component={PayDues}></Route>
            <Route exact path="/credits" component={SelectCreditScreen}></Route>
            <Route exact path={ROUTES.PERMISSION_REQUEST_FAILURE} component={Failure} />
            <Route exact path="/ui-elements" component={UiElements} />
            <Route exact path={ROUTES.PERMISSION_REQUEST_PATH} component={PermissionRequest} />
            <Route exact path={ROUTES.PERMISSION_REQUEST_SUCCESS_PATH} component={Success} />
            <Route exact path={ROUTES.PERMISSION_REQUEST_REJECTED} component={Rejected} />
            <Route exact path={"/error"} component={Error} />
            <Route exact path={ROUTES.IDENTIFIED_WITH_PASSPORT} component={IdentifiedWithPassport} />
            <Route exact path={ROUTES.WAIT_FOR} component={WaitFor} />
            <Route exact path={ROUTES.SCORING_SCREEN} component={Scoring} />

            <Route exact path={ROUTES.CREDIT_UI} component={Ui} />

          </PageErrorBoundary>

        </Layout>

      </Switch>
    </Router>
  );
}

export default PageRouter;