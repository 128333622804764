import { useEffect, useState } from 'react'
// import BackButton from "../components/BackButton"
// import { createUseStyles } from 'react-jss';
import { useHistory } from "react-router-dom"
import React from 'react'
import creditProducts from '../services/GET/creditProducts';
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CreditCard from '../components/creditProducts/creditCard';
import Loading from '../components/loading';
import ButtonBox from '../components/consents/ButtonBox';

const useStyles = makeStyles({
    pageContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        padding: "1em",
    },
    msg: {
        fontFamily: "sans-serif",
        fontSize: "1.3em",
        marginTop: "0.5em",
        marginLeft: "0.3em",
        '@media (min-width: 400px)': {
            marginTop: 10
        },
        '@media (min-width: 450px)': {
            marginTop: 20
        }
    },
    innerContainer: {
        paddingTop: 0
    }
})

const CreditProducts = () => {
    const styles = useStyles();

    const token = localStorage.getItem('token')
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const [credits, setCredits] = useState([
        // {
        //     "@id": "/api/credit-products/1",
        //     "description": "slkjfkds fklsdjf ksjd flksdjf lkdsj flksdjlf",
        //     "name": "Credit test 0"
        // },
        // {
        //     "@id": "/api/credit-products/2",
        //     "description": "slkjfkds fklsdjf ksjd flksdjf lkdsj flksdjlf",
        //     "name": "Credit test 1"
        // },
        // {
        //     "@id": "/api/credit-products/3",
        //     "description": "slkjfkds fklsdjf ksjd flksdjf lkdsj flksdjlf",
        //     "name": "Credit test 2"
        // }

    ])

    const [selectedCreditId, setSelectedCreditId] = useState('')
    const [selectedCreditName, setSelectedCreditName] = useState('')

    useEffect(() => {
        creditProducts(token).then((resp) => {
            setCredits(resp.data);
            setLoading(false)
            console.log(resp)
            if (resp.data.length === 1) {
                setSelectedCreditId(resp.data[0]['@id']);
                setSelectedCreditName(resp.data[0]['name'])
            }
        })
    }, []);

    const submitCredit = () => {

        localStorage.setItem('selectedCreditProduct', selectedCreditId);
        localStorage.setItem('selectedCreditName', selectedCreditName);
        // localStorage.setItem("showCreditStatus", true) // to be shown static screen 
        // at this moment is moved in PEP screen

        history.push('/permission-screen');
    }

    if (loading) {
        return (
            <Loading />
        )
    }

    if (credits.length === 1) {

        const credit = credits[0];
        return (
            <Container className={styles.pageContainer}>
                <div className={styles.innerContainer}>
                    <div className={styles.msg}>  <Typography variant="h6">Предлагаме Ви кредит</Typography></div>

                    <CreditCard
                        credit={credit}
                        setSelectedCreditId={setSelectedCreditId}
                        selectedCreditId={selectedCreditId}
                        setSelectedCreditName={setSelectedCreditName}
                    />

                    <ButtonBox >
                        <Button color="primary" variant="contained" onClick={() => { submitCredit() }}>Заяви</Button>
                    </ButtonBox>

                </div>

            </Container>
        )
    } else if (credits.length > 1) {
        // multiple credits only ONE can be selected
        return (
            <Container className={styles.pageContainer}>
                Credit Info Screen
                <Typography variant="h4" component="h1" className={styles.msg}>Предлагаме Ви кредит</Typography>

                {credits.map((credit, index) => {
                    return <CreditCard
                        credit={credit}
                        setSelectedCreditId={setSelectedCreditId}
                        selectedCreditId={selectedCreditId}
                        setSelectedCreditName={setSelectedCreditName}
                        key={index}
                    />
                })}

                {selectedCreditId === ''
                    ?
                    <ButtonBox>
                        <Button color="inherit" variant="contained" >Заяви</Button>
                    </ButtonBox>
                    :
                    <ButtonBox>
                        <Button color="primary" variant="contained" onClick={() => { submitCredit() }}>Заяви</Button>
                    </ButtonBox>
                }

            </Container>
        )
    }

    return (
        <Container className={styles.pageContainer}>
            Credit Info Screen
            <Typography variant="h4" component="h1" className={styles.msg}>Нямаме предложения за кредит в момента.</Typography>
        </Container>
    )
}

export default CreditProducts;