import { format } from "date-fns";

export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const START_URL = API_BASE_URL + "/start";

export const PAY_DUE = API_BASE_URL + "/paydue";

export const CREDIT = API_BASE_URL + "/credit";

export const CREDIT_PRODUCTS = API_BASE_URL + "/credit-products";

export const GET_CREDIT = (creditID) => `${API_BASE_URL}/credit/${creditID}`;

export const GET_DOCUMENTS_FOR_CREDIT = (creditID) =>
  `${API_BASE_URL}/credit/${creditID}/documents`;

export const GET_USER_CREDITS = API_BASE_URL + "/credits";

export const APPLY = API_BASE_URL + "/apply";

export const POST_CONSENT = (creditID) => `${API_BASE_URL}/consent/${creditID}`;

export const CHECK_PAYMENT_PENDING = (creditID) =>
  `${API_BASE_URL}/credit/${creditID}/check-pending-dues`;

export const CREATE_PERMISSION_REQUEST =
  API_BASE_URL + "/createPermissionRequest";

export const GET_PERMISSION_REQUEST = (hash) =>
  API_BASE_URL + "/permission-request/" + hash;

export const SUCCESS_PERMISSION_REQUEST = (hash) =>
  API_BASE_URL + "/permission-request/success" + hash;

export const GET_CREDIT_UI = (creditHash) =>
  API_BASE_URL + "/credit/ui/" + creditHash;

export const FORMAT_DATE = (date) => format(new Date(date), "dd.MM.yyyy");

export const ROUTES = {
  PAY_DUES: "/pay-dues",
  DUES: "/dues",
  PERMISSION_REQUEST: (id) => "/permission-request/" + id,
  PERMISSION_REQUEST_PATH: "/permission-request/:id",
  CREDIT: "/credit",
  PERMISSION_REQUEST_FAILURE: "/permission-request/failure",
  PERMISSION_REQUEST_SUCCESS: (token) => "/permission-request/success/" + token,
  PERMISSION_REQUEST_SUCCESS_PATH: "/permission-request/success/:token",
  PERMISSION_REQUEST_REJECTED: "/permission-request/rejected/:message",
  PERMISSION_REQUEST_REJECTED_PATH: (message) =>
    `/permission-request/rejected/${message}`,
  CREDIT_UI: "/credit/ui/:creditHash",
  CREDIT_UI_PATH: (creditHash) => "/credit/ui/" + creditHash,
  IDENTIFIED_WITH_PASSPORT: "/with-passport",
  REQUEST_SUBMITTED: "/request-submitted/:message",
  REQUEST_SUBMITTED_PATH: (message) => `/request-submitted/${message}`,
  WAIT_FOR: "/msg/:message",
  WAIT_FOR_PATH: (message) => `/msg/${message}`,
  SCORING_SCREEN: "/scoring/:message",
  SCORING_SCREEN_PATH: (message) => `/scoring/${message}`,
  HISTORY: "/history",
};

export const STATUS = {
  REJECTED: "REJECTED",
  CONFIRMED: "CONFIRMED",
  PENDING: "PENDING",
  IDENTIFIED_WITH_PASSPORT: "IDENTIFIED_WITH_PASSPORT",
};

export const LANDING_TEXTS = {
  1: "Кредитна линия през приложението Settle е финансов продукт, с лимит до 500 лева, предлаган от Вива Кредит ООД, с ЕИК 201995287. ",
  2: "За да кандидатствате за кредит, е необходимо на следващата стъпка да дадете съгласие, личните Ви данни да бъдат споделени с Вива Кредит ООД, с ЕИК 201995287. При идентификация, моля да използвате личната си карта, а не паспорта си.",
};

export const DOCUMENT_TYPE = {
  CREDIT_LINE_SEF: "credit_line_sef",
  CREDIT_LINE_GENERAL_TERMS: "credit_line_general_terms",
  CREDIT_LINE_CONTRACT: "credit_line_contract",
};

export const BACK_TO_APP_URL =
  "settle-sandbox://settledemo.page.link/?action=navigate:home&customProps=eyJyb3V0ZU5hbWUiOiJWZXJpZnlBY2NvdW50Iiwic3RhY2siOiJIb21lIn0=&dismissAfterInteraction=true&dismissDelay=0";

// export const INCOME_OPTIONS = {
//   "funds_origin.title": "Декларация за произход на средства",
//   "funds_origin.income_from_work": "Трудово/гражданско правоотношение",
//   "funds_origin.savings": "Спестявания",
//   "funds_origin.pension": "Пенсия и/или обезщетение",
// };

export const SHARE_PERSONAL_DATA = "SHARE_PERSONAL_DATA";
export const AGREE_WITH_CREDITOR_PERSONAL_DATA_RULES =
  "AGREE_WITH_CREDITOR_PERSONAL_DATA_RULES";
export const PEP = "PEP";
export const RELATED_TO_PEP = "RELATED_TO_PEP";
export const FUNDS_ORIGIN = "FUNDS_ORIGIN";
export const ACCEPT_SEF = "ACCEPT_SEF";
export const ACCEPT_GENERAL_TERMS = "ACCEPT_GENERAL_TERMS";
export const ACCEPT_CONTRACT = "ACCEPT_CONTRACT";