import React from "react";
import { makeStyles, Card, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  infoBox: {
    boxShadow: "1px 1px 8px 2px #DCDCDC",
    borderRadius: "20px",
  },
}));

const LandingInfo = ({ text, bold }) => {
  const classes = useStyles();
  return (
    <Card className={classes.infoBox}>
      <CardContent>
        <Typography
          style={bold && { fontWeight: 600 }}
          variant="subtitle1"
          align="center"
        >
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default LandingInfo;
