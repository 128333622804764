import React from "react";
import { Box, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const unselect = (value, selectedConsents, currentSelected) => {
  const copy = currentSelected.slice();
  const filtered = copy.filter((element) => {
    return element.value !== value;
  });

  selectedConsents(filtered);
};

const MultipleConsents = ({ consents, selectedConsents, currentSelected }) => {
  const options = consents.options;

  const select = (key, label, value) => {
    const isSelected = currentSelected.find((element) => {
      return element.value === value;
    });

    if (!isSelected) {
      selectedConsents((prev) => [...prev, { key, label, value }]);
    } else {
      unselect(value, selectedConsents, currentSelected);
    }
  };

  return (
    <FormControl component="fieldset" p={0}>
      <Typography align="center" variant="subtitle2">
        {consents.title}
      </Typography>
      <FormGroup>
        {options.map(({ key, label, value }, i) => {
          return (
            <Box key={i} m={0.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onClick={() => select(key, label, value)}
                  />
                }
                label={label}
              />
            </Box>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default MultipleConsents;
