import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { ReactComponent as FailureIcon } from "../../images/failure.svg";
import { BACK_TO_APP_URL } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "15vh",
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const Rejected = ({ match }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FailureIcon />
      <div>
        <Typography align="center" variant="subtitle2">
          {match.params.message}
        </Typography>
      </div>

      <a
        href={process.env.REACT_APP_BACK_TO_APP_URL || BACK_TO_APP_URL}
        style={{
          width: "100vw",
          textAlign: "center",
          marginTop: 60,
          marginBottom: 30,
        }}
      >
        <Button variant="contained" color="primary">
          Изход
        </Button>
      </a>
    </div>
  );
};

export default Rejected;
