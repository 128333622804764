import { GET_CREDIT_UI } from '../../utils/constants';
import HttpService from '../http';

const getCreditUi = (creditHash) => {

    return HttpService.get(GET_CREDIT_UI(creditHash), {
        headers: {
            "Accept": "application/json",
            "Accept-language": "bg",
        }
    }).catch(e => {
        // alert(JSON.stringify(e.message ))
    })
}
export default getCreditUi;