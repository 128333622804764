import React, { useState } from "react";
import { Box, Grid, Button, Typography } from "@material-ui/core";
// import FormLabel from '@material-ui/core/FormLabel';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as ImageEU } from "../../images/EUF.svg";
import { ReactComponent as ImageTerms } from "../../images/terms.svg";
import { ReactComponent as ImageContract } from "../../images/contract.svg";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
// import DocumentLinkNew from "../documentLinkNew";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    marginTop: 100,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: "1em",
    "& a": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  msg: {
    fontFamily: "sans-serif",
    fontSize: "1.3em",
    marginBottom: "1.2em",
    "@media (min-width: 400px)": {
      marginTop: 10,
    },
    "@media (min-width: 450px)": {
      marginTop: 20,
    },
  },
  image: {
    width: "100%",
  },
  btn: {
    marginTop: "4vh",
    textAlign: "center",
    "@media (min-height: 650px)": {
      marginTop: "8vh",
    },
    "@media (min-height: 700px)": {
      marginTop: "10vh",
    },
    "@media (min-height: 750px)": {
      marginTop: "15vh",
    },
    "@media (min-height: 800px)": {
      marginTop: "18vh",
    },
  },
}));

const ConsentsDocument = ({
  title,
  setSelectedConsent,
  sendConsents,
  buttonText,
  document,
}) => {
  const styles = useStyles();
  const [checked, setChecked] = useState(false);

  const handleChange =
    () =>
      ({ target }) => {
        setChecked(target.checked);
      };

  const ImageContent = () => {
    if (title && title.includes("Запознах се и приемам")) {
      return <ImageEU className={styles.image} />;
    }
    if (title && title.includes("Договор за кредит")) {
      return <ImageContract className={styles.image} />;
    }
    if (title && title.includes("Запознах се и съм съгласен")) {
      return <ImageTerms className={styles.image} />;
    }
    return null;
  };

  return (
    <Container className={styles.pageContainer}>
      <div className={styles.msg}>
        {" "}
        <Typography variant="h6"></Typography>
      </div>

      <Box>
        <ImageContent />

        <Box mt={2}>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                // label={buttonText}
                label={<div dangerouslySetInnerHTML={{ __html: title }} />}
                onClick={handleChange()}
                checked={checked}
              />
            </FormGroup>
          </FormControl>
        </Box>

        {/* <DocumentLinkNew
          text={<div dangerouslySetInnerHTML={{ __html: title }} />}
          url={document.url}
        /> */}

        <Grid className={styles.btn} container justifyContent="center">
          <Button
            onClick={sendConsents}
            variant="contained"
            disabled={!checked}
            color="primary"
          >
            {buttonText || "Продължи"}
          </Button>
        </Grid>
      </Box>
    </Container>
  );
};

export default ConsentsDocument;
