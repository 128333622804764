import React, { useContext } from "react";
// import Transfers from '../components/history/transfers';
// import Transactions from '../components/history/Transactions';
import Info from "../components/history/Info";

import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Context } from "../context/Context";
import { useHistory } from "react-router-dom";
import { FORMAT_DATE } from "../utils/constants";

const useStyles = makeStyles({
  msg: {
    height: 60,
    marginTop: 60,
    textAlign: "left",
    width: "90%",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  con: {
    marginTop: 60,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  fundsBox: {
    marginTop: "1.5em",
    boxShadow: "1px 2px 3px 2px lightGray",
    borderRadius: 20,
    width: "90%",
  },
  innerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  left: {
    width: "60%",
  },
  right: {
    width: "40%",
    fontSize: 24,
    margin: 0,
  },
});

const History = (props) => {
  // get data from context when ready
  const classes = useStyles();
  const { currentCredit } = useContext(Context);
  const { totalPaidLimit, totalUtilisedLimit } = currentCredit;
  const history = useHistory();

  if (!currentCredit.transfers) {
    history.goBack();
    return <></>;
  }

  const onlyPaid = currentCredit.dues.filter(
    (x) => Number(x.outstandingAmount) <= 0
  );

  console.log(onlyPaid);

  return (
    <Container className={classes.con}>
      <Card className={classes.fundsBox}>
        <CardContent className={classes.card}>
          <Box className={classes.innerBox}>
            <div className={classes.left}>
              <Typography component="p">Усвоена главница:</Typography>
              <Typography color="secondary" component="p">
                {FORMAT_DATE(new Date())}
              </Typography>
            </div>
            <Typography
              color="secondary"
              component="p"
              align="right"
              className={classes.right}
            >
              {Number(totalUtilisedLimit || 0).toFixed(2)} BGN
            </Typography>
          </Box>

          <Box className={classes.innerBox}>
            <div className={classes.left}>
              <Typography component="p">Възстановена главница:</Typography>
              <Typography component="p">{FORMAT_DATE(new Date())}</Typography>
            </div>
            <Typography component="p" align="right" className={classes.right}>
              <b> {Number(totalPaidLimit || 0).toFixed(2)}</b> BGN
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {onlyPaid.map((x, i) => (
        <Info {...x} key={i} />
      ))}

      {!onlyPaid.length && (
        <Typography style={{ marginTop: 80 }} align="center">
          Няма История
        </Typography>
      )}
    </Container>
  );
};

export default History;
