import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as ClockIcon } from "../../images/clock.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "15vh",
    height: "46vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const WaitFor = ({ match }) => {
  const { message } = match.params;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ClockIcon />
      <div>
        <Typography align="center" variant="subtitle2">
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default WaitFor;
