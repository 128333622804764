import React, { useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import checkToken from '../services/GET/checkToken';
import Loading from '../components/loading';

const Credit = (props) => {

    localStorage.removeItem('creditId');

    const query = () => {
        return new URLSearchParams(useLocation().search);
    }

    const history = useHistory()
    const token = query().get("tokenCFS") || localStorage.getItem("tokenCFS");
    if (!token || token === "null") history.push("/");
    localStorage.setItem('token', token);

    useEffect(() => {
        checkToken(token).then((resp) => {
            const next = resp.data.nextUrl;
            if (next.startsWith('/credit/')) {
                const creditId = next.substr(8, next.length);
                localStorage.setItem('creditId', creditId);
                return history.push('/permission-screen')
            }
            history.push(next);

        }).catch((e) => {
            window.location.href = "/error"
        })
    }, [token]);

    return (
        <div>
            <Loading />
        </div>
    )
}

export default Credit;