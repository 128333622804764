import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { Button, Grid, Box, Typography } from "@material-ui/core";
import { ReactComponent as ImagePEP1 } from "../../images/PEP_1.svg";
import { ReactComponent as ImagePEP2 } from "../../images/PEP_2.svg";

const useStyles = makeStyles((theme) => ({
  pageContainer: {

    display: "flex",
    flexDirection: "column",
    "& a": {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
  },
  msg: {
    marginTop: 30,
    fontFamily: "sans-serif",
    fontSize: "1.3em",
    marginBottom: "1.2em",
  },
  image: {
    marginTop: "15%",
    width: "100%",
  },
  btn: {
    textAlign: "center",

    marginTop: 20,
    marginBottom: 30

  },
}));

const ConsentsPEP = ({ consents, setSelectedConsent, label, sendConsents }) => {
  const [canSubmit, setCanSubmit] = useState(true);
  const styles = useStyles();
  const handleCheck = (selected) => () => {
    const opt1 = consents.options[0]
    const opt2 = consents.options[1]
    
    if (selected === 0) {
      setSelectedConsent(opt1)
    } else {
      setSelectedConsent(opt2)
    }
    setCanSubmit(false);
  };
  
  const handleSubmit = () => {
    sendConsents()
  }
  
  localStorage.setItem("showCreditStatus", true) // to be shown static screen
  
  return (
    <Container className={styles.pageContainer}>
      <Box mt={8}>
        <div className={styles.msg}>
          <Typography variant="h6">Моля посочи</Typography>
        </div>
        {consents.title.includes("свързано лице") ? (
          <ImagePEP2 className={styles.image} />
        ) : (
          <ImagePEP1 className={styles.image} />
        )}
        <FormControl>
          <Typography>
            <div dangerouslySetInnerHTML={{ __html: consents.title }} />
          </Typography>
          {/* {consents.title} */}
          <RadioGroup>
            <FormControlLabel
              onChange={handleCheck(0)}
              value="yes"
              control={<Radio color="primary" />}
              label="Да"
            />
            <FormControlLabel
              onChange={handleCheck(1)}
              value="no"
              control={<Radio color="primary" />}
              label="Не"
            />
          </RadioGroup>
          {/* <FormLabel component="legend">{32}</FormLabel>
                <RadioGroup>
                    <FormControlLabel value="yes" control={<Radio />} label="Да" />
                    <FormControlLabel value="no" control={<Radio />} label="Не" />
                </RadioGroup> */}
        </FormControl>
      </Box>

      {/* <Consents title={consents.title} consents={options} selectedConsent={setSelectedConsent} /> */}

      <Grid className={styles.btn} container justifyContent="center">
        <Button
          variant="contained"
          disabled={canSubmit}
          color="primary"
          onClick={handleSubmit}
        >
          Продължи
        </Button>
      </Grid>
    </Container>
  );
};

export default ConsentsPEP;
