import React from "react";
import { makeStyles } from "@material-ui/styles";
import { format } from "date-fns";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  trans: {
    display: "grid",
    gridTemplateColumns: "10fr 8fr 10fr",
    paddingTop: 13,
  },
  cur: {
    fontSize: 12,
  },
}));

const Transactions = ({ arr }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {arr &&
        arr.length > 0 &&
        arr.map((data, i) => {
          return (
            <div key={i}>
              <div className={classes.trans}>
                <Typography align="center" variant="caption">
                  {data.description}
                </Typography>
                <Typography variant="subtitle2" align="left">
                  -{data.amount}{" "}
                  <span className={classes.cur}>{data.currency}</span>
                </Typography>
                <Typography align="center">
                  {format(new Date(data.createdAt), "dd.MM.yyyy")}
                </Typography>
              </div>
              <Divider />
            </div>
          );
        })}
    </div>
  );
};

export default Transactions;
