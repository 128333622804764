import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import getUserCredits from '../services/GET/getUserCredits';
import Loading from '../components/loading';
// import { createUseStyles } from 'react-jss';
import getCredit from '../services/GET/getCredit';

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider';
// THIS SCREEN IS CURRENTLY NOT IN USE
const useStyles = makeStyles({
    link: {
        textDecoration: "none",
        color: "#000000"
    },
    title: {
        margin: {
            top: 30,
            bottom: 30
        }
    }
})

// TODO handle if credit only one 

const SelectCreditScreen = ({ location }) => {

    const history = useHistory();
    const styles = useStyles();
    const token = location.state.token;

    const [credits, setCredits] = useState('');
    const [loading, setLoading] = useState(true);

    // const selectedCredit = (token, creditId) => {

    //     setLoading(true);

    //     // redirecting to next screen depending on applicationStatus
    //     getCredit(token, creditId).then((credit) => {
    //         const applicationStatus = credit.data.applicationStatus;
    //         if (applicationStatus === "GET_CONSENT") {
    //             history.push({
    //                 // for consents
    //                 pathname: '/permission-screen',
    //                 state: { token, creditId }
    //             })
    //         } else if (applicationStatus === "READY") {
    //             history.push({
    //                 pathname: '/credit-approved',
    //                 state: { token, creditId }
    //             })
    //         } else {
    //             history.push({
    //                 pathname: '/request-submitted',
    //                 state: { applicationStatus }
    //             })
    //         }
    //         console.log(applicationStatus)
    //     })
    // }

    useEffect(() => {
        getUserCredits(token)
            .then((resp) => {
                setCredits(resp.data);
                setLoading(false);
            })
            .catch((e) => {
                // window.location.href = "/error"
            })
    }, []);

    const selectCredit = (creditId) => {

        getCredit(token, creditId).then((resp) => {
            console.log(resp)
            // const creditProductId = resp.data.creditProductId

            history.push({
                pathname: "/permission-screen",
                state: {
                    selectedCreditProduct: null,
                    token,
                    creditId
                } // will be used current user token
            })
        })

    }


    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <Container className={styles.pageContainer}>
            <Typography variant="h4" component="h1" className={styles.msg}>Моите кредити</Typography>
            {
                credits.map(({ name, id }, index) => {
                    return (
                        <Container onClick={() => selectCredit(id)}>
                            <Divider m={1} />
                            <Typography component="p" className={styles.link}>{name}</Typography>
                        </Container>
                    )
                })
            }
        </Container>
    )
}

export default SelectCreditScreen;