import { GET_DOCUMENTS_FOR_CREDIT } from "../../utils/constants";
import HttpService from "../http";

export const getDocuments = () => {
  const token = localStorage.getItem("tokenCFS");
  const creditId = localStorage.getItem("creditId");

  // const token = "359882727096"

  // const creditId = "308";

  return HttpService.get(GET_DOCUMENTS_FOR_CREDIT(creditId), {
    headers: {
      Accept: "application/json",
      "Accept-language": "bg",
      "X-Request-ID": "823a156c-828e-4831-8bc9-cf32c4ffd759",
      "x-settapp-token": token,
    },
  }).catch((e) => {
    // alert(JSON.stringify(e.message ))
  });
};
