import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Container,
  Grid,
  Button,
} from "@material-ui/core";
import { ReactComponent as ClockIcon } from "../../images/clock.svg";
import getCredit from "../../services/GET/getCredit";
import APPLICATION_STATUS from "../../utils/APPLICATION_STATUS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as ImageOK } from "../../images/ok.svg";
import { ROUTES } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20vh",
    height: "46vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageContainer: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: "1em",
  },
  image: {
    marginTop: "30%",
    width: "100%",
  },
  btn2: {
    marginTop: "10vh",
    textAlign: "center",
    "@media (min-height: 600px)": {
      marginTop: "15vh",
    },
    "@media (min-height: 700px)": {
      marginTop: "27vh",
    },
    "@media (min-height: 800px)": {
      marginTop: "34vh",
    },
  },
}));

const Scoring = ({ match }) => {
  const { message } = match.params;
  const classes = useStyles();
  const token = localStorage.getItem("tokenCFS");
  const creditId = localStorage.getItem("creditId");
  const history = useHistory();
  const [status, setStatus] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const callBack = async () => {
      const {
        data: { applicationStatus, messageForCustomer },
      } = await getCredit(token, creditId);

      if (applicationStatus !== APPLICATION_STATUS.CREDIT_SCORING_IN_PROGRESS) {
        setStatus(applicationStatus);
        clearInterval(intervalID);
        console.log("cleared");
      }

      if (applicationStatus === APPLICATION_STATUS.REJECTED) {
        history.push(
          ROUTES.PERMISSION_REQUEST_REJECTED_PATH(messageForCustomer)
        );
      }

      console.log(applicationStatus);
    };

    const intervalID = setInterval(callBack, 2000);

  }

  if (status === APPLICATION_STATUS.GET_CONSENT) {
    return (
      <Container className={classes.pageContainer}>
        <ImageOK className={classes.image} />
        <Container>
          <Typography
            align="center"
            style={{ marginTop: 20 }}
            variant="subtitle2"
          >
            Вашата заявка за кредит е одобрена. Можете да пристъпите към
            подписване на договор.
          </Typography>
        </Container>

        <Grid className={classes.btn2} justifyContent="center">
          <Button
            onClick={() => history.push("/permission-screen")}
            variant="contained"
            color="primary"
          >
            Продължи
          </Button>
        </Grid>
      </Container>
    );
  }

  return (
    <div className={classes.root}>
      <ClockIcon />
      <div>
        <Typography align="center" variant="subtitle2">
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default Scoring;
