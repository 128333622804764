import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import BackBtn from "./components/backBtn";
import svgBG from "./images/BG.svg";

const useStyles = makeStyles((theme) => ({
  bgImage: {
    position: "absolute",
    backgroundImage: `url(${svgBG})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    // backgroundAttachment: "fixed",
    height: "8vh",
    width: "100vw",
    borderRadius: 10,
    top: 40,
    backgroundPosition: "top center",
    overflow: "hidden",
  },
}));
console.log(123111)
const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.bgImage}></div>
      <div style={{ position: "relative", maxWidth: "100%" }}>
        <BackBtn active={true} />
      </div>
      <Grid
        alignItems="stretch"
        direction="column"
        container
        style={{ position: "relative" }}
      >
        {children}
      </Grid>
    </div>
  );
};

export default Layout;
