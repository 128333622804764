import { CREATE_PERMISSION_REQUEST } from "../../utils/constants";
import HttpService from "../http";

export const createPermissionRequest = async (phone) => {

    try {
        const { data } = await HttpService.post(CREATE_PERMISSION_REQUEST, {}, {
            headers: {
                "X-Request-ID": "25cb52d1-901d-4aae-b88a-2d4ae3f52ac2",
                "x-settapp-token": phone
            }
        });

        return data;

    } catch (error) {
        window.location.href = "/error"
        console.log(error);
    }
}