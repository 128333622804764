import { GET_USER_CREDITS } from "../../utils/constants";
import HttpService from "../http";

const getUserCredits = (token) => {
  return HttpService.get(GET_USER_CREDITS, {
    headers: {
      Accept: "application/json",
      "Accept-language": "bg",
      "X-Request-ID": "87c2be75-7256-48c8-932b-4492308628e6",
      "x-settapp-token": token,
    },
  }).catch((e) => {
    // alert(JSON.stringify(e.message ))
  });
};

export default getUserCredits;
