import React from "react";
import { Grid, Button } from "@material-ui/core";
import Transactions from "../../components/history/Transactions";
import Info from "../../components/history/Info";

const randomDate = () =>
  new Date(+new Date() - Math.floor(Math.random() * 10000000000));

const arr1 = [
  { type: "Плащане", date: randomDate(), cash: "12,67" },
  { type: "Плащане", date: randomDate(), cash: "4,67" },
  { type: "Плащане", date: randomDate(), cash: "312,67" },
  { type: "Плащане", date: randomDate(), cash: "13,67" },
  { type: "Плащане", date: randomDate(), cash: "122,67" },
  { type: "Плащане", date: randomDate(), cash: "142,67" },
  { type: "Плащане", date: randomDate(), cash: "16,64" },
  { type: "Плащане", date: randomDate(), cash: "126,67" },
];

const UiElements = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-around"
      style={{ marginTop: 100, height: "200vh", width: "100%" }}
    >
      <Button color="primary" variant="contained">
        Primary
      </Button>
      <Button color="secondary" variant="contained">
        Secondary
      </Button>

      <Transactions arr={arr1} />
      <Info />
    </Grid>
  );
};

export default UiElements;
