import React from 'react';
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'


const data = {
    // principal: 250,
    monthlyPayment: 1.50
}

const useStyles = makeStyles({
    title: {
        margin: {
            top: 30,
            bottom: 30
        }
    },
    field: {
        display: 'flex',
        justifyContent: "space-between",
        padding: 0
    },
    pageContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        padding: "1em",
    },
    msg: {
        fontFamily: "sans-serif",
        color: "gray",
        fontSize: "1.3em",
        marginBottom: "1.5em",
        marginTop: "1.5em"
    },
    outherBox: {
        padding: 0
    },
    text: {
        marginBottom: "1.5em"
    }
});

const Details = ({ location }) => {
    const head = location.state.utilisedLimit; // wrong value but dynamic
    const styles = useStyles();

    return (
        <Container className={styles.pageContainer}>
            <Typography variant="h4" component="h1" className={styles.msg}>Детайли</Typography>
            <Container component="section" className={styles.outherBox}>
                <Container className={styles.field}>
                    <Typography component="p" className={styles.text}>Главница</Typography>
                    <Typography component="p" className={styles.text}>{Number(head).toFixed(2)} лв.</Typography>
                </Container>
                <Container className={styles.field}>
                    <Typography component="p" className={styles.text}>Месечно плащане</Typography>
                    <Typography component="p" className={styles.text}>{Number(data.monthlyPayment).toFixed(2)} лв.</Typography>
                </Container>
            </Container>
        </Container>
    )
}

export default Details;