import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { checkPermissionRequest } from "../../services/GET/permissionReq";
import { ROUTES, STATUS } from "../../utils/constants";

const Success = ({ match }) => {
  const history = useHistory();
  localStorage.removeItem("selectedCreditProduct");

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { phone, status } = await checkPermissionRequest(
      null,
      match.params.token
    );

    if (status === STATUS.IDENTIFIED_WITH_PASSPORT) {
      history.push(ROUTES.IDENTIFIED_WITH_PASSPORT);
    } else if (phone) {
      localStorage.setItem("tokenCFS", phone);
      setTimeout(() => {
        history.push("/credit");
      }, 600);
    }
  }

  return (
    <Typography align="center" style={{ marginTop: 100 }}>
      Пренасочване...
    </Typography>
  );
};

export default Success;
