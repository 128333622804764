import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  fundsBox: {
    marginTop: "1.5em",
    boxShadow: "1px 2px 3px 2px lightGray",
    borderRadius: 20,
  },
  innerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  left: {
    width: "30%",
  },
  right: {
    width: "70%",
    fontSize: 35,
    margin: 0,
  },
  card: {
    width: "90%",
  },
});

const FundsInfo = (props) => {
  const { availableLimit, totalLimit, utilisedLimit } = props;
  const classes = useStyles();

  return (
    <Card className={classes.fundsBox} m={1}>
      <CardContent className={classes.card}>
        <Box className={classes.innerBox}>
          <Typography className={classes.left}>Лимит</Typography>
          <Typography variant="h4" align="right" className={classes.right}>
            {Number(totalLimit).toFixed(2)} BGN
          </Typography>
        </Box>

        <Box className={classes.innerBox}>
          <Typography className={classes.left}>Използван</Typography>
          <Typography variant="h4" align="right" className={classes.right}>
            {Number(utilisedLimit).toFixed(2)} BGN
          </Typography>
        </Box>

        <Box className={classes.innerBox}>
          <div className={classes.left}>
            <Typography >Оставащ</Typography>
            {/* <Typography color="secondary" component="p" >(Блокиран)</Typography> */}
          </div>
          <Typography variant="h4" align="right" className={classes.right}>
            <b> {Number(availableLimit).toFixed(2)}</b> BGN
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FundsInfo;
