import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography/index";

const useStyles = makeStyles((theme) => ({
  backBtn: {
    display: "inline-flex",
    justifyContent: "left",
    padding: 20,
    paddingTop: 10,
  },
  backIcon: {
    background: "lightGray",
    borderRadius: "50%",
    padding: 5,
  },
  btnText: {
    padding: "2px 5px 5px 15px",
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.primary,
  },
}));

const BackBtn = ({ active }) => {
  const classes = useStyles();
  const history = useHistory();

  console.log(window.location.pathname);

  const goBack = () => {
    // const path = window.location.pathname;

    // if (path === "/dues" || path === ROUTES.PAY_DUES) {
    history.goBack();
    // } else {
    // history.push(`/credit?token=${localStorage.getItem("tokenCFS")}`);
    // }
  };

  if (
    window.location.pathname === "/" ||
    window.location.pathname === "/permission-request/failure" ||
    window.location.pathname === "/permission-screen" ||
    window.location.pathname === "/credit-approved" ||
    window.location.pathname.includes("scoring")
  ) {
    return null;
  }

  if (active) {
    return (
      <Container className={classes.backBtn}>
        <AiOutlineLeft className={classes.backIcon} />
        <Typography onClick={goBack} className={classes.btnText}>
          Назад
        </Typography>
      </Container>
    );
  }

  return (
    // not in use for now
    // <Container className={classes.backBtn}>
    <Container onClick={() => history.goBack()} className={classes.backBtn}>
      <AiOutlineLeft className={classes.backIcon} />
      <Typography className={classes.btnText}>Назад</Typography>
    </Container>
  );
};

export default BackBtn;
