import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line
import FundsInfo from "../components/creditApprovedScreen/fundsInfo";
import Links from "../components/creditApprovedScreen/links";
import getCredit from "../services/GET/getCredit";
import Loading from "../components/loading";
import { Typography, Button, Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { ReactComponent as SuccessImg } from "../images/success.svg";
import { Context } from "../context/Context";
import { ROUTES } from "../utils/constants";
import { getDocuments } from "../services/GET/getDocuments";
import { BACK_TO_APP_URL } from "../utils/constants";

const useStyles = makeStyles({
  pageContainer: {
    marginTop: 100,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: "1em",
  },
  image: {
    marginTop: 160,
    marginBottom: "8%",
    width: "100%",
  },
  btnBox: {
    margin: 15,
    textAlign: "center",
    marginTop: 50,
  },
  bottomButtons: {
    marginTop: 40,
    display: "flex",
    justifyContent: "space-around",
    "& .MuiButton-contained": {
      minWidth: 120,
    },
  },
  endBtn: {
    maxWidth: 120,
  },
  fundsContainer: {
    marginTop: 50,
  },
});

const CreditApprovedScreenHome = (props) => {
  const context = useContext(Context);
  const classes = useStyles(props);
  const token = localStorage.getItem("tokenCFS");
  const creditId = localStorage.getItem("creditId");
  const history = useHistory();
  const [credit, setCredit] = useState("");
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    fetchData();
  }, [token, creditId]);

  async function fetchData() {
    const respCredit = await getCredit(token, creditId);
    const { data: documents } = await getDocuments();
    setDocs(documents);
    setCredit(respCredit.data);
    context.setCredit(respCredit.data);
    setLoading(false);
  }

  if (loading) {
    return <Loading />;
  }

  if (localStorage.getItem("isNewCredit")) {
    const handleClick = () => {
      localStorage.removeItem("isNewCredit");
      history.push("/permission-screen");
    };
    return (
      <div>
        <SuccessImg className={classes.image} />
        <Container>
          <Typography
            align="center"
            style={{ paddingBlock: 10 }}
            variant="subtitle2"
          >
            Успешно подписан договор
          </Typography>
          <Typography align="center" variant="subtitle2">
            Кредитът Ви вече е активен!
          </Typography>
        </Container>
        <Grid className={classes.btn1} container justifyContent="center">
          <Button
            style={{ margin: "auto", marginTop: 20 }}
            onClick={handleClick}
            variant="contained"
            color="primary"
          >
            Продължи
          </Button>
        </Grid>
      </div>
    );
  }

  return (
    <Container className={classes.pageContainer}>
      <Links docs={docs}/>
      <div className={classes.fundsContainer}>
        <FundsInfo {...credit} />
      </div>

      <div className={classes.btnBox}>
        <Button
          onClick={() => history.push(ROUTES.PAY_DUES)}
          variant="contained"
          color="primary"
        >
          Плати задължения
        </Button>
      </div>

      <div className={classes.bottomButtons}>
        <Button
          onClick={() => history.push(ROUTES.HISTORY)}
          variant="contained"
          color="secondary"
          className={classes.endBtn}
        >
          История
        </Button>
        <a href={process.env.REACT_APP_BACK_TO_APP_URL || BACK_TO_APP_URL}>
          <Button
            //   onClick={() => history.push(ROUTES.PAY_DUES)}
            variant="contained"
            color="secondary"
            className={classes.endBtn}
          >
            Изход
          </Button>
        </a>
      </div>
    </Container>
  );
};

export default CreditApprovedScreenHome;
