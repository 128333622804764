import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../components/loading";
import getCreditUi from "../../services/GET/getCreditUi";

const Ui = ({ match }) => {
  const { creditHash } = match.params;
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setLoading(true);
      const {
        data: { creditId, phone },
      } = await getCreditUi(creditHash);
      localStorage.setItem("creditId", creditId);
      localStorage.setItem("tokenCFS", phone);
      history.push("/credit-approved");
      setLoading(false);
    } catch (error) {
      window.location.href = "/error";
      console.log(error);
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  return <div>{creditHash}</div>;
};

export default Ui;
