import React, { Fragment } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { HiOutlineExternalLink } from "react-icons/hi";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  linksBox: {
    width: "100%",
    marginTop: "1.5em",
    marginBottom: "1.5em",

    margin: "auto",
  },
  link: {
    justifyContent: "space-between",
    padding: 0,
  },
  text: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    fontSize: 25,
    marginRight: 10,
  },
  iconLink: {
    marginLeft: 10,
    marginRight: 0,
    float: "right",
  },
  linkText: {
    fontSize: 16,
    float: "right",
    width: "100%",
    color: "#4D596F",
  },
});

const DocumentLink = ({ text, url }) => {
  // later will be added URL for the redirection to the document
  const styles = useStyles();
  return (
    <div className={styles.linksBox}>
      <Typography className={styles.link} component="span">
        <Typography variant="body2" className={styles.text}>
          <AiFillInfoCircle className={styles.icon} />
          <Fragment>
            {url && (
              <Link
                className={styles.linkText}
                to={{ pathname: url }}
                target="_blank"
              >
                {text}
              </Link>
            )}
            {!url && text}
            <HiOutlineExternalLink
              className={[styles.icon, styles.iconLink].join(" ")}
            />
          </Fragment>
        </Typography>
      </Typography>
    </div>
  );
};

export default DocumentLink;
