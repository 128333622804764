import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { FORMAT_DATE } from "../../utils/constants";

const useStyles = makeStyles({
  box: {
    margin: 0,
    boxShadow: "1px 2px 3px 2px lightGray",
    borderRadius: 20,
    padding: 0,
    marginTop: 20,
  },
  taxesAndMaturityBox: {
    padding: 20,
  },
  text: {
    fontSize: 35,
  },
  deadline: {
    padding: 10,
  },
});

const DuesForMonths = (props) => {
  const { dues } = props;
  const classes = useStyles();

  
  if (!dues) {
    return null;
  }
  const onlyUnpaid = dues.filter((x) => x.outstandingAmount > 0);

  return (
    <>
      {onlyUnpaid.map((due, i) => {
        // const {} = due;
        return (
          <Container className={classes.box} key={i}>
            <Grid
              className={classes.taxesAndMaturityBox}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                container
                justifyContent="space-between"
                direction="row"
                alignItems="center"
              >
                <Grid>
                  <Typography component="p">Такса</Typography>
                  <Typography component="p">
                    до {FORMAT_DATE(due.period)}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.text}>
                    <b>
                      {Number(due.outstandingDelayFeesAmountDue).toFixed(2)}
                    </b>{" "}
                    BGN
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                direction="row"
                alignItems="center"
              >
                <Grid>
                  <Typography component="p">Лихва </Typography>
                  <Typography component="p">
                    до {FORMAT_DATE(due.paymentDeadline)}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.text}>
                    <b>{Number(due.outstandingFeesAmountDue).toFixed(2)}</b> BGN
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        );
      })}
    </>
  );
};

export default DuesForMonths;
