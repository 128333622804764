import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    marginTop: "20vh",
    textAlign: "center",
    "@media (min-height: 600px)": {
      marginTop: "30vh",
    },
    "@media (min-height: 700px)": {
      marginTop: "40vh",
    },
  },
});

const ButtonBox = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} item justifyContent="center">
      {children}
    </Grid>
  );
};

export default ButtonBox;
