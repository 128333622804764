import { START_URL } from "../../utils/constants";
import HttpService from "../http";


export const handleStart = async () => {
    try {
        const { data } = await HttpService.get(START_URL);

        return data;

    } catch (error) {
        window.location.href = "/error"
        console.log(error);
    }
}