import React from 'react';
import MultipleConsents from '../consents/multipleConsents';
import Grid from '@material-ui/core/Grid/index';
import Button from '@material-ui/core/Button/index';
import { Typography } from '@material-ui/core';
import { ReactComponent as IncomeImage } from '../../images/income.svg'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        marginTop: 100,
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        padding: "1em",
    },
    msg: {
        fontFamily: "sans-serif",
        fontSize: "1.3em",
        marginBottom: "1.2em",
        '@media (min-width: 400px)': {
            marginTop: 10
        },
        '@media (min-width: 450px)': {
            marginTop: 20
        }
    },
    imageIncome: {
        width: "100%",
    },
    btn: {
        marginTop: "9vh",
        textAlign: "center",
     
    },
}))

const ConsentsIncome = ({ consents, setSelectedConsent, sendConsents, currentSelected }) => {
    const styles = useStyles();

    return (
        <Container className={styles.pageContainer}>
            <div className={styles.msg}> <Typography variant="h6" >Моля посочи</Typography ></div>
            <IncomeImage className={styles.imageIncome} />
            <MultipleConsents
                consents={consents}
                selectedConsents={setSelectedConsent}
                currentSelected={currentSelected}
            />
            <Grid className={styles.btn} container justifyContent="center" >
                <Button variant="contained" color="primary" onClick={sendConsents}>
                    Продължи
                </Button>
            </Grid>
        </Container>
    )
}

export default ConsentsIncome;