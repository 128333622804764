import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as FailureIcon } from "../../images/failure.svg";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginInline: 20,
    marginTop: "5vh",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  secondBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Error = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
        <FailureIcon />
        <Typography align="center" variant="subtitle1">
          Възникна техническа грешка. Моля опитайте малко по-късно.
        </Typography>
      <div className={classes.secondBox}>
       
        <Button
        style={{minWidth:150}}
          onClick={() => history.goBack()}
          variant="contained"
          color="primary"
        >
          Продължи
        </Button>
      </div>
    </div>
  );
};

export default Error;
