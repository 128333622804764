import React from 'react';
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { ReactComponent as ClockIcon } from "../images/clock.svg"

const useStyles = makeStyles({
    text: {
        paddingTop: 40,
        fontSize: 20,
        textAlign: "center",

    },
    pageContainer: {
        marginTop: 100,
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        padding: "1em",
    }
})

const RequestSubmittedScreen = ({ match }) => {// API response as a props (response)

    const styles = useStyles();

    const message = match.params.message

    return (
        <Container className={styles.pageContainer}>
            <ClockIcon />
            <Typography variant="h6" component="h1" className={styles.text}>{message}</Typography>
        </Container>
    )
}

export default RequestSubmittedScreen;