import React from "react";
import { Card, CardContent, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FORMAT_DATE } from "../../utils/constants";

const useStyles = makeStyles({
  fundsBox: {
    marginTop: "1.5em",
    boxShadow: "1px 2px 3px 2px lightGray",
    borderRadius: 20,
    width: "90%",
  },
  innerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  left: {
    width: "50%",
  },
  right: {
    width: "60%",
    fontSize: 32,
    margin: 0,
  },
});

const Info = ({ outstandingFeesAmountDue, outstandingDelayFeesAmountDue }) => {
  const classes = useStyles();

  return (
    <Card className={classes.fundsBox}>
      <CardContent className={classes.card}>
        <Box className={classes.innerBox}>
          <div className={classes.left}>
            <Typography component="p">Такса просрочен</Typography>
            <Typography color="secondary" component="p">
              {FORMAT_DATE(new Date())}
            </Typography>
          </div>
          <Typography
            color="secondary"
            component="p"
            align="right"
            className={classes.right}
          >
            {Number(outstandingDelayFeesAmountDue).toFixed(2)} BGN
          </Typography>
        </Box>

        <Box className={classes.innerBox}>
          <div className={classes.left}>
            <Typography component="p">Лихва до</Typography>
            <Typography component="p">{FORMAT_DATE(new Date())}</Typography>
          </div>
          <Typography component="p" align="right" className={classes.right}>
            <b> {Number(outstandingFeesAmountDue).toFixed(2)}</b> BGN
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Info;
