import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'

const useStyles = makeStyles({
    loading: {
        textAlign: "center",
        marginTop: "40vh"
    }
})

const Loading = () => {

    const classes = useStyles();

    return (
        <Container className={classes.loading}>
            <CircularProgress />
        </Container>
    )
}

export default Loading;