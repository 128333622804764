import { CREDIT_PRODUCTS } from "../../utils/constants";
import HttpService from "../http";

const creditProducts = () => {
  return HttpService.get(CREDIT_PRODUCTS, {
    headers: {
      Accept: "application/json",
      "Accept-language": "bg",
      "X-Request-ID": "823a156c-828e-4831-8bc9-cf32c4ffd759",
    },
  }).catch((e) => {
    // alert(JSON.stringify(e.message ))
  });
};

export default creditProducts;
