import React from "react";
import { ReactComponent as ClockImg } from "../images/clock.svg";
import { ReactComponent as RejectedImage } from "../images/failure.svg";

import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import { checkPermissionRequest } from "../services/GET/permissionReq";
import Loading from "../components/loading";
import { useHistory } from "react-router-dom";
import { ROUTES, STATUS } from "../utils/constants";
import { createPermissionRequest } from "../services/POST/createPermissionReq";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 60,
    height: "50vh",
    display: "flex",
    textAlign: "center",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const PermissionRequest = ({
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();
  const hash = localStorage.getItem("tokenCFS");
  const [loading, setLoading] = React.useState(true);
  const [status, setStatus] = React.useState("");
  const history = useHistory();
  const [counter, setCounter] = React.useState(0);
  var interval = 0;

  const retry = async () => {
    try {
      const checkPerReq = await checkPermissionRequest(hash, id);
      if (checkPerReq) {
        if (checkPerReq.status === STATUS.REJECTED) {
          setStatus(STATUS.REJECTED);
        } else if (checkPerReq.status === STATUS.PENDING) {
          setStatus(STATUS.PENDING);
        } else if (checkPerReq.status === STATUS.CONFIRMED) {
          setStatus(STATUS.CONFIRMED);
          localStorage.setItem("tokenCFS", checkPerReq.phone);
          setTimeout(() => {
            history.push(ROUTES.CREDIT);
          }, 800);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      window.location.href = "/error";
      console.log(error);
    }
  };

  useEffect(() => {
    interval = setInterval(() => {
      setCounter((x) => x + 1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (status === STATUS.CONFIRMED || status === STATUS.REJECTED) {
      console.log("clear here");
      clearInterval(interval);
    } else {
      if (counter > 1) {
        retry();
      }
    }

    console.log(counter);
  }, [counter]);

  useEffect(() => {
    fetchReq();
  }, []);

  async function sendNewPR() {
    const newOne = await createPermissionRequest(hash);
    if (newOne && newOne.hash) {
      history.push(`/permission-request/${newOne.hash}`);
      window.location.reload();
    }
  }

  async function fetchReq() {
    try {
      setLoading(true);
      const checkPerReq = await checkPermissionRequest(hash, id);
      if (checkPerReq) {
        if (checkPerReq.status === STATUS.REJECTED) {
          setStatus(STATUS.REJECTED);
          setLoading(false);
        } else if (checkPerReq.status === STATUS.PENDING) {
          setStatus(STATUS.PENDING);
          setLoading(false);
        } else if (checkPerReq.status === STATUS.CONFIRMED) {
          setStatus(STATUS.CONFIRMED);
          localStorage.setItem("tokenCFS", checkPerReq.phone);
          setLoading(false);
          setTimeout(() => {
            history.push(ROUTES.CREDIT);
          }, 800);
        } else if (checkPerReq.status === STATUS.IDENTIFIED_WITH_PASSPORT) {
          history.push(ROUTES.IDENTIFIED_WITH_PASSPORT);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      window.location.href = "/error";
      console.log(error);
    }
  }
  if (loading) {
    return <Loading />;
  }

  if (id === "failure") return null;

  return (
    <div className={classes.root}>
      {status === STATUS.PENDING && (
        <>
          <ClockImg />
          <Typography>Приемете споделянето на данни</Typography>
        </>
      )}
      {status === STATUS.REJECTED && (
        <>
          <RejectedImage />
          <Typography>Отказан</Typography>
          <Button onClick={sendNewPR} variant="contained" color="primary">
            Изпрати отново
          </Button>
        </>
      )}
      {status === STATUS.CONFIRMED && (
        <>
          <Typography>Пренасочване...</Typography>
        </>
      )}
    </div>
  );
};

export default PermissionRequest;
