import { CHECK_PAYMENT_PENDING } from "../utils/constants";
import HttpService from "./http";

export const checkPendingPayment = async () => {
  const creditID = localStorage.getItem("creditId");
  const token = localStorage.getItem("tokenCFS");

  const { data } = await HttpService.get(CHECK_PAYMENT_PENDING(creditID), {
    headers: {
      "x-settapp-token": token,
    },
  });

  return data;
};
