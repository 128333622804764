import { POST_CONSENT } from "../../utils/constants";
import HttpService from "../http";

const postConsents = (
  token,
  creditId,
  creditProductId,
  options,
  title,
  type
) => {
  console.log(options);
  if (!JSON.stringify(options).includes("key")) {
    options = [];
  }
  if (JSON.stringify(options).includes("fundsOriginConsent")) {
    options = options[0];
  }

  const url = POST_CONSENT(creditId);

  const body = JSON.stringify({
    credit: creditProductId,
    options: options,
    title: title,
    type: type,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-language": "bg",
      "X-Request-ID": "823a156c-828e-4831-8bc9-cf32c4ffd759",
      "x-settapp-token": token,
    },
  };

  console.log(body);

  return HttpService.post(url, body, config).catch((e) => {
    // alert(JSON.stringify(e.message ))
  });
};

export default postConsents;
