import React from "react";
// import { createUseStyles } from 'react-jss';
import { Typography } from "@material-ui/core";
import { AiFillInfoCircle } from "react-icons/ai";
import { HiOutlineExternalLink } from "react-icons/hi";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { DOCUMENT_TYPE } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.5em",
    padding: 0,
  },
  text: {
    margin: 0,
    padding: 0,
    display: "flex",
    borderSpacing: 20,
  },
  icon: {
    fontSize: 25,
    margin: {
      right: 10,
    },
  },
  container: {
    padding: 0,
    "& a": {
      color: theme.palette.primary.main,
      width:"100%",
      display: "flex",
      justifyContent:"space-between"
    },
  },
}));

const Links = ({ docs = [] }) => {
  const styles = useStyles();
  console.log(docs);
  const Doc = ({ data: { name, fileUrl } }) => (
    <Container className={styles.link}>
      <a
        style={{ color: "#4D596F" }}
        rel="noopener noreferrer"
        target="_blank"
        href={fileUrl}
      >
        <Typography component="p" className={styles.text}>
          <AiFillInfoCircle className={styles.icon} />
          {name}
        </Typography>
        <HiOutlineExternalLink color="primary" className={styles.icon} />
      </a>
    </Container>
  );

  if (!docs.length) return null;

  const contract = docs.find(
    (x) => x.type === DOCUMENT_TYPE.CREDIT_LINE_CONTRACT
  );
  const terms = docs.find(
    (x) => x.type === DOCUMENT_TYPE.CREDIT_LINE_GENERAL_TERMS
  );
  const sef = docs.find((x) => x.type === DOCUMENT_TYPE.CREDIT_LINE_SEF);

  return (
    <Container className={styles.container}>
      <Doc data={contract} />
      <Doc data={terms} />
      <Doc data={sef} />
    </Container>
  );
};

export default Links;
