import { CREDIT } from "../../utils/constants";
import HttpService from "../http";

const checkToken = (token) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Accept-language": "bg",
      "X-Request-ID": "823a156c-828e-4831-8bc9-cf32c4ffd759",
      "x-settapp-token": token,
    },
  };

  return HttpService.get(CREDIT, config).catch((e) => {
    // alert(e);
    // window.location.href = "/error"
  });
};

export default checkToken;
