import React from 'react';

const store = {
  currentCredit: {
    creditId: 0,
    dues: [],
    transfers: []
  }
}

export const Context = React.createContext(store);


export const ContextProvider = ({ children }) => {
  const [currentCredit, setCurrentCredit] = React.useState({})

  const setCredit = (credit) => {
    setCurrentCredit({ ...credit })
  }

  return (
    <Context.Provider value={{
      currentCredit,
      setCredit
    }}>
      {children}
    </Context.Provider>
  )
}