import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button/index';
// TODO refactoring with MUI
import { makeStyles, Grid } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel';
import { ReactComponent as ImageOK } from '../../images/ok.svg'
// import { ReactComponent as ImageNO } from '../../images/noCredit.svg'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Loading from '../loading';
import { Box, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container'
// import DocumentLink from '../documentLink';
import { useHistory } from 'react-router';


const useStyles = makeStyles({
    pageContainer: {
        marginTop:20,
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        padding: "1em",
    },
    msg: {
        fontFamily: "sans-serif",
        fontSize: "1.3em",
        marginTop: "0.5em",
        marginLeft: "0.3em",
        '@media (min-width: 400px)': {
            marginTop: 10
        },
        '@media (min-width: 450px)': {
            marginTop: 20
        }
    },
    image: {
        marginTop: "15%",
        width: "100%"
    },
    btn: {
        marginTop: "20vh",
        textAlign: "center",
        "@media (min-height: 600px)": {
            marginTop: "25vh",
        },
        "@media (min-height: 700px)": {
            marginTop: "34vh",
        },
        "@media (min-height: 800px)": {
            marginTop: "37vh",
        },
    },
    btn2: {
        marginTop: "10vh",
        textAlign: "center",
        "@media (min-height: 600px)": {
            marginTop: "15vh",
        },
        "@media (min-height: 700px)": {
            marginTop: "27vh",
        },
        "@media (min-height: 800px)": {
            marginTop: "34vh",
        },
    }
})

const ConsentsApply = ({ consents, applyHandler }) => {
    const history = useHistory()

    if (consents.length < 1) { return (<Loading />) }
    const styles = useStyles();
    const [checked, setChecked] = useState(false);
    const { key } = consents[0].options[0];
    const formLabel = consents[0].title;
    const [canSubmit, setCanSubmit] = useState(true);

    useEffect(() => {
        if (checked) {

            setCanSubmit(false)
        }
    }, [checked])

    const handleChange = () => {
        setChecked(val => !val)
    }

    const submit = () => (e) => {
        e.preventDefault()
        applyHandler(e)
    }

    if (localStorage.getItem("showCreditStatus")) {
        // extra screen
        //  todo -- maybe show this screen on another page
        const handleClick = () => {
            localStorage.removeItem("showCreditStatus");
            history.push('/permission-screen')
        }

        return (
            <Container className={styles.pageContainer}>

                <ImageOK className={styles.image} />
                <Container>
                    <Typography align="center" style={{ marginTop: 20 }} variant="subtitle2">
                        Вашата заявка за кредит
                        получи успешно одобрение!
                        Можете да продължите с
                        попълването на документите.
                    </Typography>
                </Container>

                <Grid className={styles.btn2} justifyContent="center">
                    <Button onClick={handleClick} variant="contained" color="primary">Продължи</Button>
                </Grid>
            </Container >
        )
    }

    return (
        <Container className={styles.pageContainer}>
            <div className={styles.msg}>
                <Typography variant="h6"></Typography>
            </div>
            <form onSubmit={submit()}>
                <Box mt={2} justify='center' className="box" >
                    <FormLabel component="legend">{formLabel}</FormLabel>
                    <br />
                    {/* <DocumentLink text={consents[1].buttonText} url={consents[1].document.url} /> */}

                    <FormControlLabel
                        control={<Checkbox color="primary" name={key} checked={checked} onChange={handleChange} />}
                        label={consents[0].options[0].label}
                    />

                    {/* <DocumentLink text={consents[2].buttonText} /> */}

                    <Grid container className={styles.btn2} justifyContent="center">
                        <Button disabled={canSubmit} type="submit" variant="contained" color="primary" >
                            {/* {consents[2].buttonText} */}
                            Продължи
                        </Button>
                    </Grid>

                </Box>
            </form>
        </Container>
    )
}

export default ConsentsApply;