import { GET_PERMISSION_REQUEST, SUCCESS_PERMISSION_REQUEST } from "../../utils/constants";
import HttpService from "../http";

export const checkPermissionRequest = async (phone, hash) => {

    try {
        const { data } = await HttpService.get(GET_PERMISSION_REQUEST(hash), {
            headers: {
                "X-Request-ID": "25cb52d1-901d-4aae-b88a-2d4ae3f52ac2",
                "x-settapp-token": phone
            }
        });

        return data;

    } catch (error) {
        window.location.href = "/error"
        console.log(error);
    }
}

export const successPermissionRequest = async (hash) => {
    try {
        const { data } = await HttpService.get(SUCCESS_PERMISSION_REQUEST(hash), {
            headers: {
                "X-Request-ID": "25cb52d1-901d-4aae-b88a-2d4ae3f52ac2",
            }
        });

        return data;

    } catch (error) {
        window.location.href = "/error"
        console.log(error);
    }
}