import React from 'react';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/styles';
import { Typography, CardContent, Card, Box } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    selected: {
        boxShadow: "1px 1px 8px 2px #DCDCDC",
        borderRadius: "20px"
    },
    notSelected: {
        boxShadow: "1px 1px 8px 2px #DCDCDC",
        borderRadius: "20px"
    },
    checked: {
        color: '#00cc00',
        float: "right",
        fontSize: "larger"
    },
    creditTitle: {
        fontWeight: 600,
        fontSize: 40
    }
}));

const CreditCard = ({ credit, setSelectedCreditId, selectedCreditId, setSelectedCreditName }) => {
    // const isSelected = credit["@id"] === selectedCreditId

    const handleSelect = () => {
        console.log(credit['@id']);
        setSelectedCreditId(credit['@id']);
        setSelectedCreditName(credit['name']);
    }

    const classes = useStyles();
    return (
        <Box mt={2}>
            <Card className={classes.selected} onClick={handleSelect}>
                <CardContent>
                    <Typography style={{
                        fontWeight: 600,
                        fontSize: 32
                    }} variant="h5" >
                        {credit.name}
                        {/* <CheckCircleOutlineIcon className={classes.checked} /> */}
                    </Typography>
                    <Typography variant="subtitle1" >
                        {credit.description}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    )
    // eslint-disable-next-line
    // if (isSelected) {
    // } else {
    //     return (
    //         <Box mt={2}>
    //             <Card className={classes.notSelected} onClick={handleSelect}>
    //                 <CardContent>
    //                     <Typography className={classes.creditTitle} variant="h5">
    //                         {credit.name}
    //                     </Typography>
    //                     <Typography variant="body2" component="p">
    //                         {credit.description}
    //                     </Typography>
    //                 </CardContent>
    //             </Card>
    //         </Box>

    //     )
    // }
}

export default CreditCard;