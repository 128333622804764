import { Typography } from "@material-ui/core";
import React, { Component } from "react";
// import Error from "../pages/static/Error";
import Layout from "./../Layout";
export class PageErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, message: error.message, error: error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout>
          <div style={{ marginTop: "15vh" }}>
            {JSON.stringify(this.state)}
            <Typography>some error ---url: {window.location.href}</Typography>
            <Typography>
              ---token: {localStorage.getItem("tokenCFS")} ---
            </Typography>
            <Typography>
              ---creditId: {localStorage.getItem("creditId")}
            </Typography>
            {/* <Error /> */}
          </div>
        </Layout>
      );
    }

    return this.props.children;
  }
}

export default PageErrorBoundary;
